import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../components/mdx/page";
import { TableSimple } from "../../components/widgets/table";
import { useMembership, useMemberdata, useApiKey } from "../../utils/member-data";
import { formatDateTime } from "../../utils/format-helpers";
export const Membership = () => {
  const membership = useMembership();
  const apiKey = useApiKey();
  const table = {
    columns: ["Item", "Value"],
    ["Item"]: ["First and Last Name", "Email", "Member Since", "Membership ID", "API Key", "Plans", "Access Level", "Grandfathered Year"],
    ["Value"]: [(membership?.user?.firstName ?? "") + " " + (membership?.user?.lastName ?? ""), membership?.user?.email, formatDateTime(membership?.user?.created), membership?.user?.id, apiKey, membership?.user?.plans?.reduce((cum, plan) => (cum === "" ? "" : cum + ", ") + plan, ""), membership?.level?.access, membership?.level?.year < 3000 ? membership?.level?.year : "n/a"]
  };
  return <>
        <TableSimple data={table} mdxType="TableSimple" />
    </>;
};
export const Memberdata = () => {
  const [meta, setMeta] = useMemberdata();
  return <>
        <p><code style={{
        "wordBreak": "break-all"
      }}><small>
            {JSON.stringify(meta)}
        </small></code></p>
    </>;
};
export const _frontmatter = {};
const layoutProps = {
  Membership,
  Memberdata,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <h1>{`Membership Information`}</h1>
    <p>{`This page shows important information about your membership and site access. Occasionally, we might ask for this information to answer questions or to debug technical difficulties.`}</p>
    <Membership mdxType="Membership" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      